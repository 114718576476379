.text-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
  }
  
  .block1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 64px;
    font-weight: normal;
    color: #4c4f56ff;
    margin-bottom: 2rem;
  }
  
  .block2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: #725bbdff;
    margin-bottom: 2rem;
  }
  
  .block3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: lighter;
    color: #736b8aff;
    margin-bottom: 2rem;
  }
  
  .contact-button {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    background-color: #725bbd;
    color: #ffffff;
    border: 4px solid #725bbd;
    border-radius: 50px;
    padding: 1rem 3rem;
    cursor: pointer;
    align-self: left;
    transition: all 0.25s ease-in-out;
  }
.contact-button:hover {
  background-color: transparent;
  color: #A69CC8;
  border-color: #A69CC8;
}
.modal-header {
  font-family: 'Comfortaa-Regular', cursive;
  font-size: 2rem;
  font-weight: bold;
  color: #4c4f56ff;
}
  
.modal-button {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  background-color: #725bbd;
  color: #fff;
  border: 2px solid #725bbd;
  border-radius: 50px;
  padding: 1rem 2rem;
  cursor: pointer;
}
.modal-content {
  box-sizing: border-box;
  width: 50%;
  max-width: 400px;
  margin: 0 auto;
  border: 3px solid #725bbdff;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  top: 20%;
  background-color: #9470ff15;
}
.modal-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.modal-form label {
  font-size: 14px;
  font-weight: bold;
  color: #4c4f56ff;
  font-family: 'Comfortaa-Light', sans-serif;
  display: block;
  text-align: left;
}
.modal-form input[type="text"],
.modal-form input[type="email"],
.modal-form input[type="note"] {
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #4c4f56ff;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #4c4f56ff;
  display: block;
  outline-color: #4c4f56ff;
}
.modal-form button[type="submit"] {
  background-color: #725bbdff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
}
.logo-container {
  display: flex;
  align-items: center;
}
.logo {
  height: 50px;
  margin-right: 25px;
}
.company-name {
  font-size: 24px;
  margin: 0;
  font-family: 'Comfortaa-Regular', sans-serif;
  color: #725bbdff;
}
.newform1-wrapper {
  display: none;
}
.show-form .newform1-wrapper {
  display: block;
}
.newform1-grid {
  max-width: 200px;
  margin: 0 auto;
}
.NewForm1 {
  width: 50%;
  margin: 0 auto;
}
/* Styles for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Styles for the form container */
.form-container {
  position: relative;
  z-index: 1; /* make sure the form appears above the overlay */
  max-width: 600px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
/* Styles for the close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.form-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}